import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { ThemeToggler } from 'gatsby-plugin-dark-mode'
import Switch from "react-switch"
import Image from "gatsby-image"
 
const MyToggler = () => {
    const data = useStaticQuery(graphql`
        query {
            DayMode: file(absolutePath: { regex: "/light_mode.png/" }) {
                childImageSharp {
                    fixed(width: 25, height: 25) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            NightMode: file(absolutePath: { regex: "/night_mode.png/" }) {
                childImageSharp {
                    fixed(width: 20, height: 20) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }`
    )

    return (
      <ThemeToggler>
        {({ theme, toggleTheme }) => (
          <label>

            <Switch 
                onColor="gray"
                uncheckedIcon={
                    <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 15,
                        color: "orange",
                        paddingRight: 2
                    }}
                    >
                        <Image fixed={data.DayMode.childImageSharp.fixed} />
                    </div>
                }
                checkedIcon={
                    <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 15,
                        color: "orange",
                        paddingRight: 2
                    }}
                    >
                        <Image fixed={data.NightMode.childImageSharp.fixed} />
                    </div>
                }
                onChange={checked => toggleTheme(checked ? 'dark' : 'light')}
                checked={theme === 'dark'}
            />
          </label>
        )}
      </ThemeToggler>
    )
}


export default MyToggler